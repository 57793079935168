:root {
  --color1:       #1BAFAF;      /* Primary Dark */
  --color2:       #31CACA;      /* Primary Default */
  --color3:       #86DEDE;      /* Primary Medium */
  --color4:       #C6F4F4;      /* Primary Light */
  --color5:       #FFFFFF;      /* White */
  --color6:       #202020;      /*  Black */
  --color7:       #BCBBBB;      /* Medium (Gray)*/
  --color8:       #F6F6F6;      /* Extra Light ( Extra Light Gray) */
  --color9:       #797979;      /* Dark (Dark Gray)*/
  --color10:      #E1E1E1;      /* Light (Light Gray)*/
  --color11:      #1BAFAF;      /* Green5*/
  --color12:      #FF4F61;      /* Red */
  --color13:      #31CACA;      /* Green */
  --color14:      #EAFAFA;      /* Primary Extra light */
  --color15:      #C0C0C0;


  --chart-color1:  #EF5E5E;
  --chart-color2:  #FF974A;
  --chart-color3:  #FFC542;
  --chart-color4:  #00C2FF;
  --chart-color5:  #0062FF;
  --chart-color6:  #0028B4;
}
